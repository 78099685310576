<template>
  <!-- Тестовая страница мероприятия -->
  <div class="container-page">
    <div class="activity-schedule">
      <!-- title -->
      <div class="activity-schedule__title-wrapper">
        <h4 class="activity-schedule__title">График мероприятий международного двустороннего сотрудничества</h4>
        <div class="activity-schedule__btn-create">
          <ButtonStock title="Создать мероприятие" @click="addCard()" v-if="canEdit" />
        </div>
      </div>

      <!-- filter -->
      <div class="activity-schedule__filter">
        <div class="activity-schedule__filter-block">
          <p class="activity-schedule__filter-header">Группа стран</p>
          <Multiselect
            placeholder="Выберите группу"
            label="text"
            searched="true"
            v-model="filters.countryGroupId"
            valueProp="id"
            track-by="text"
            :options="countryGroupList"
            @select="changeCountryGroup"
          />
        </div>

        <div class="activity-schedule__filter-block">
          <p class="activity-schedule__filter-header">Страна</p>
          <Multiselect
            placeholder="Выберите страну"
            label="text"
            :searchable="true"
            v-model="filters.countryId"
            valueProp="id"
            track-by="text"
            :options="computedCountryList"
          />
        </div>
        <div class="activity-schedule__filter-block">
          <p class="activity-schedule__filter-header">Сортировка по дате</p>
          <Multiselect
            placeholder="Выберите сортировку"
            label="text"
            v-model="filters.sortAsc"
            valueProp="id"
            track-by="text"
            :options="sortOptions"
          />
        </div>
        <div class="activity-schedule__filter-block activity-schedule__filter-block--buttons">
          <ButtonStock title="Показать" @click="apply()" />
          <ButtonStock class="activity-schedule__cancel-btn" btnType="secondary" title="Сбросить" @click="clear()" />
        </div>
      </div>
      <div class="switcher-container">
        <RedesignedSwitchComponent :checked="isMapView" textTrue="Карта" textFalse="Календарь">
          <template #one="{ textTrue }">
            <div class="one" :class="{ active: isMapView }" @click="changeViewType(true)">
              {{ textTrue }}
            </div>
          </template>
          <template #two="{ textFalse }">
            <div class="two" :class="{ active: !isMapView }" @click="changeViewType(false)">
              {{ textFalse }}
            </div>
          </template>
        </RedesignedSwitchComponent>
        <hr />
      </div>

      <div class="event-map-container" v-show="isMapView">
        <Loader
          style="margin: 0 auto"
          class="d-flex text-center align-items-center justify-content-center event-loader"
          v-if="!loaded"
        />
        <EventMapComponent :events="page" v-if="loaded" />
      </div>
      <!-- schedule -->
      <div class="activity-schedule__calendar" v-show="!isMapView">
        <Calendar
          :columns="getColumnCalendar"
          :step="1"
          :attributes="attributes"
          @load-calendar="loadCalendar"
          @edit-card="editCard"
        />
      </div>

      <!-- table -->
      <div v-if="page.items && page.items.length" class="activity-schedule-table">
        <PerfectScrollbar>
          <div class="activity-schedule__table table-container-new">
            <table class="stockTable">
              <thead>
                <tr>
                  <th class="th-icons" v-if="canEdit"></th>
                  <th class="th-name"><p>Название</p></th>
                  <th class="th-other"><p>Дата и время</p></th>
                  <th class="th-other"><p>Место</p></th>
                  <th class="th-other"><p>Мероприятие</p></th>
                  <th class="th-other"><p>Регистрация</p></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
                  <td v-if="canEdit" class="td-icons first-column-fixed">
                    <div>
                      <EventSvgTableEditIcon :item-id="item.id" :readonly="!canEdit" @edit="editCard" />
                      <EventSvgTableRemoveIcon :item-id="item.id" :readonly="!canEdit" @delete="deleteCard" />
                    </div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>
                    <template v-for="(date, index) in item.eventDatesList" :key="date">
                      <div>
                        {{ $momentFormat(date, 'DD MMMM YYYY HH:mm')
                        }}{{ index != item.eventDatesList.length - 1 ? ', ' : '' }}
                      </div>
                    </template>
                  </td>
                  <td>{{ item.place }}</td>
                  <td>{{ item.eventType.name }}</td>
                  <td>
                    <p
                      :class="{
                        green: item.isRegistrationOpen,
                        red: !item.isRegistrationOpen,
                      }"
                    >
                      {{ item.isRegistrationOpen ? 'Открыта' : 'Закрыта' }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </PerfectScrollbar>
        <div v-if="page.pagesTotal > 1">
          <PaginationRedesigned
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
  import Multiselect from '@vueform/multiselect';
  import moment from 'moment';

  import Calendar from '@/common/components/redesigned/Calendar';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import resizeEventListener from '@/common/mixins/resizeEventListener';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import Loader from '@/components/Loader';

  import RedesignedSwitchComponent from '../../../common/components/redesigned/RedesignedSwitchComponent.vue';
  import API from '../api/event';
  import EventMapComponent from '../components/EventMapComponent';
  import EventSvgTableEditIcon from '../components/EventSvgTableEditIcon.vue';
  import EventSvgTableRemoveIcon from '../components/EventSvgTableRemoveIcon.vue';
  import { eventSortOptions } from '../utils/constants';

  export default {
    name: 'Events',
    components: {
      ButtonStock,
      EventMapComponent,
      Loader,
      EventSvgTableEditIcon,
      EventSvgTableRemoveIcon,
      PaginationRedesigned,
      Multiselect,
      Calendar,
      RedesignedSwitchComponent,
      PerfectScrollbar,
    },
    mixins: [security, filtermanager, resizeEventListener],
    data() {
      return {
        eventListForCalendar: [],

        countryGroupList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        countryList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],

        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filters: {
          countryGroupId: 0,
          countryId: 0,
          sortAsc: false,
        },
        page: {},
        canEdit: false,
        month: null,
        year: null,
        isMapView: false,
        loaded: false,
        sortOptions: eventSortOptions,

        windowWidth: window.innerWidth,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.MpkEventsEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filters = filter;
        }
        const countryId = this.$route?.query?.countryId;
        if (countryId) {
          this.filters.countryId = countryId;
        }
        this.loadPage(false, false);
      });

      Utils.loadSelectOptions('countiesGroupList', this.countryGroupList);
      this.changeCountryGroup();
    },
    mounted() {
      this.loadCalendar({ month: this.month, year: this.year });
    },
    methods: {
      changeCountryGroup() {
        Utils.loadSelectOptions('countryList', this.countryList, { groupId: this.filters.countryGroupId }, true);
      },

      loadCalendar(date) {
        this.month = date.month;
        this.year = date.year;
        var params = Object.assign({ pageNumber: 1, pageSize: 0, year: date.year, month: date.month }, this.filters);
        API.search(params).then((response) => {
          this.eventListForCalendar = [];
          response.data.items.forEach((el) => {
            if (el.eventDatesList) {
              el.eventDatesList.forEach((d) => {
                var eventToPush = Object.assign({}, el);
                eventToPush.eventDatesList = [d];
                eventToPush.time = moment(d).format('HH:mm');
                this.eventListForCalendar.push(eventToPush);
              });
            }
          });
        });
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist, useCache) {
        this.loaded = false;
        var params = Object.assign({}, this.filters);
        //  if (params.sortAsc == 0) params.sortAsc = null;
        params.pageNumber = this.request.pageNumber;
        params.pageSize = this.request.pageSize;

        if (this.isMapView) {
          params.incomingOnly = true;
          params.pageNumber = 1;
          params.pageSize = 0;
        }

        API.search(params, useCache).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
          this.loaded = true;
        });
      },

      apply() {
        this.saveFilter(this.filters);
        this.onPageChange(1, false);
        if (!this.isMapView) this.loadCalendar({ month: this.month, year: this.year });
      },
      clear() {
        if (this.filters.countryGroupId != null) {
          this.filters.countryGroupId = 0;
          this.changeCountryGroup();
        }
        this.filters.countryId = 0;
        //  this.filters.sortAsc = 0;
        this.filters.sortAsc = null;
        this.onPageChange(1, false);
        this.saveFilter(this.filters);
      },

      addCard() {
        this.$router.push({ name: 'EventCardAdd' });
      },
      editCard(id) {
        this.$router.push({
          name: 'EventCard',
          params: { id: id, action: this.canEdit ? 'edit' : 'view' },
        });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      changeViewType(ev) {
        this.isMapView = ev;
        this.loadPage();
      },

      /**
       * @public
       * обрабатывается через миксин `resizeEventListener`
       */
      onResize() {
        this.windowWidth = window.innerWidth;
      },
    },
    computed: {
      attributes() {
        return [
          // Attributes for todos
          ...this.eventListForCalendar.map((todo) => ({
            dates: todo.eventDatesList,
            dot: {
              color: todo.color,
              class: todo.statusId == 3 ? 'opacity-75' : '',
            },
            popover: {
              label: todo.name,
              visibility: 'click',
              isInteractive: true,
              modifiers: [
                {
                  name: 'flip',
                  enabled: false,
                },
              ],
            },
            customData: todo,
          })),
        ];
      },
      // для правки отображения выбранного значения в `@vueform/multiselect`
      computedCountryList() {
        return [...this.countryList];
      },
      getColumnCalendar() {
        if (this.windowWidth >= 1280) return 3;
        if (this.windowWidth > 900) return 2;
        return 1;
      },
    },
    watch: {
      'filters.countryId'() {
        this.$router.push({
          query: this.filters.countryId && this.filters.countryId !== 0 ? { countryId: this.filters.countryId } : null,
        });
        this.loadPage(false, false);
      },
    },
  };
</script>

<style lang="scss">
  .activity-schedule-table {
    .ps {
      max-height: 100% !important;
    }
  }
  .activity-schedule__filter-block {
    .multiselect-dropdown {
      border: 1px solid $light-grey-5;
      box-shadow: 0px 0px 16px 0px #0000000d;
      border-radius: 4px;
      z-index: 9999;
    }
    .multiselect-option.is-selected {
      background: $dark-grey-1;
    }
    .multiselect-clear-icon {
      display: none;
    }
    .multiselect {
      border-radius: 4px;
    }
    .multiselect-option.is-pointed:hover {
      background: $light-grey-5 !important;
    }
  }

  .switcher-container {
    position: relative;
    .switch-back {
      background: none;

      border-radius: 0;
    }
    .active {
      color: $blue !important;
      border-bottom: 2px solid $blue;
    }
  }
</style>

<style lang="scss" scoped>
  @import '../styles/Events.scss';
</style>
