<template>
  <svg
    v-show="!readonly"
    class="removeIcon"
    @click.stop="deleteCard"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M2 4H3.33333H14" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M12.6663 4.00004V13.3334C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3334V4.00004M5.33301 4.00004V2.66671C5.33301 2.31309 5.47348 1.97395 5.72353 1.7239C5.97358 1.47385 6.31272 1.33337 6.66634 1.33337H9.33301C9.68663 1.33337 10.0258 1.47385 10.2758 1.7239C10.5259 1.97395 10.6663 2.31309 10.6663 2.66671V4.00004"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6.66699 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.33301 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
  export default {
    name: 'EventSvgTableRemoveIcon',
    props: {
      itemId: {
        type: Number,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: '16',
      },
    },
    methods: {
      deleteCard() {
        this.$emit('delete', this.itemId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .removeIcon {
    stroke: $base;
    transition: 0.3s;

    &:hover {
      stroke: #0000ee;
    }
  }
</style>
